*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

footer{
    position:relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: #111;
    height: auto;
    width: 100vw;
    padding-top: 40px;
    color: #fff;
}
.footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.footer-content h3{
    font-size: 2.1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 3rem;
    color: white;
}
.footer-content p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
    color: #cacdd2;
}

.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem 0 3rem 0;
}

.Icon{
    font-size: 30px;
    margin-right: 15px;
    color: white;
    cursor: pointer;
}
.Icon:hover{
  transform: scale(1.2);
  color: red;
}

.footer-bottom{
    background: #000;
    width: 100vw;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
 
}
.footer-bottom p{
   color: white;
   float: left;
   font-size: 14px;
   word-spacing: 2px;
   text-transform: capitalize;
}
.footer-bottom p a{
  color:#44bae8;
  font-size: 16px;
  text-decoration: none;
}
.footer-menu{
    float: right;
  }
  
  .footer-menu ul{
    display: flex;
  }
  
  .footer-menu ul li{
  padding-right: 10px;
  display: block;
  }
  
  .footer-menu ul li a{
    color: #cfd2d6;
    text-decoration: none;
  }
  
  .footer-menu ul li a:hover{
    color: #27bcda;
  }
  @media (max-width:500px) {
    .footer-menu ul{
      display: none;
    }
    }