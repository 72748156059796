.settings{
    display: flex;

}
.settingsWrapper{
    flex: 9;
    padding: 20px;

}

.settingsUpdateTitle{
    font-size: 26px;
    margin-bottom: 20px;
    color: Red;
}

.settingsForm{
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 20px;
    margin: 20px;
    background-color: white;

}

.settingsForm > label{
    font-size: 18px;
    margin-top: 20px;
}

.profilePic{
    object-fit: contain;
    border-radius: 50%;
    cursor: pointer;
}

.profilePic:hover{
    opacity: 0.5;
    
}


.settingsForm > input{
    background-color: white;
    color: black;
    margin: 10px 0;
    height: 30px;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 10px;
}

.settingsForm > input:focus{
    border-color: pink;

}

.settingsSubmit{
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
}