.post{
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 20px;  
    transition: 0.8s transform;
}
.post:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.4);
    z-index: 3;
    border-color: #007bff;
}

.postImg{
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: contain;
    border-radius: 20px;
    
}

.postInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postCat{
    font-family: 'Varela Round', sans-serif;
    font-size: 11px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle{
    font-family: 'Josefin Sans', sans-serif;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
    
}

.postDate{
    font-family: 'Lora', serif;
    font-style: italic;
    font-size: 13px;
    color: red;
}

.postDesc{
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 0%;
}