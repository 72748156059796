
.posts{
    background-color: whitesmoke;
    padding: 0px;
    border-radius: 20px;
}
.AllPostTitle{
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight :bold;
    margin-top: 20px;  
    transition: 0.5s transform;
    color: #27374D;
}

.AllPostTitle:hover{
    transform: translateY(-10px);
}

.dropdown{
    display: flex;
    position: absolute;
    right: 30px;
   
}
.pagination{
    justify-content: center;
}
.page-link{
    color: black; 
    background-color: white;
}
.page-link:hover{
    color: white; 
    background-color: black;
}
.page_active{
    color: white; 
    background-color: black;
}
@media (max-width:1000px) {
    .AllPostTitle{
        display: flex;
        font-size: 20px;
        margin-top: 20px;
        
    }
}
