
.singlePost{  
   display: flex;
   width: 100%;
   padding: 0px;
   background-color: white;
   overflow: scroll;
   
}

.quillEditor{
   margin-top: 10px;
   margin-bottom: 40px ;
   height: 90vh !important;
}
.ql-editor{
   padding: 0 !important;
   margin: 0;
}

.singlePostImg{
width: 100%;
height: 300px;
border-radius: 5px;
object-fit:contain;
border-radius: 20px;
}

.singlePostWrapper{
   width: 100%;
   margin: 50px;
   padding: 20px;
   padding-right: 0;
   display: flex;
   flex-direction: column;
}

.singlePostTitle{
   text-align: center;
   margin: 10px;
   font-weight: bold;
   font-size: 32px;
}
.singlePostTitleInput{
   width: 100%;
   text-align: center;
   margin: 10px;
   font-size: 22px;
   background-color: inherit;
   border: none;
   border-bottom: 1px solid black;

}
.singlePostTitleInput:focus{
   outline: none;
}
.singlePostLinkInput:focus{
   outline: none;
}


.singlePostEdit{
   float: right;
   font-size: 25px;
}

.singlePostIcon{

   margin-left: 10px;
   cursor: pointer;
}

.singlePostIcon:first-child{

   color: blue;
}
.singlePostIcon:last-child{

   color: red;
}

.singlePostInfo{
   margin-top: 15px;
   margin-bottom: 15px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 15px;
  
}
.singlePostAuthor{
   color: #61677A;
}
.singlePostDate{
   color: #61677A;
}
.singlePostDesc{

   margin-left: 0px;
   font-size: 17px;
   line-height: 25px;
   white-space: pre-wrap;
   overflow-wrap: break-word;
   overflow-x: scroll;

}
.singlePostDesc::first-letter{
   font-size: 30px;
   font-weight: 600;
}

.singlePostDescInput{
   width: 100%;
   border: none;
   font-size: 17px;
   line-height: 25px;
}
.singlePostDescInput:focus{
   outline: none;
}

.singlePostButton{
   border: none;
   background-color: teal;
   width: 100px;
   color: white;
   border-radius: 5px;
   padding: 5px;
   cursor: pointer;
   align-self: flex-end;
   margin-top: 20px;
   text-align: center;
}


.button-group{
   display: flex;
   justify-content: flex-start;
   gap: 20px;
   padding: 10px;
   align-items: center;

}
.inactiveButton{
   border: none;
   background-color: whitesmoke;
   width: 100px;
   color: rgb(74, 70, 70);
   border-radius: 5px;
   padding: 5px;
   cursor: pointer;
   align-self: flex-end;
   margin-top: 20px;
   text-align: center;
}
.singlePostButton .inactiveButton :hover{   
   outline: 3px solid #008080;
}

.linkTitle{
   color: red;
   align-self: center;
   justify-self: center;
}

.singlePostLinkInput{   
   display: block;
   width: 100%;
   text-align: center;
   font-size: 20px;
   border: none;
   
   border-bottom: 1px solid black;
}

.mt-50{
   margin-top: 80px !important;
}

@media (max-width:800px) {

   .singlePost{ 
      width: 100%;
      margin: 0px ; 
      padding: 20px;
      background-color: white;
     
   }
   .singlePostTitle{
      text-align: center;
      margin: 10px;
      font-weight: bold;
      font-size: 24px;
   }
   .singlePostDesc{
      margin-left: 0px;
      font-size: 16px;
      line-height: 26px;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      overflow-x: scroll;
   
   }
   .singlePostWrapper{
      width: 100%;
      margin: 0px;
      padding: 0px;
   }
   .singlePostImg{
      margin-top: 20px;
      width: 100%;
      height: 200px;
      border-radius: 50px;
      object-fit:contain;
      }

   }
   @media (max-width:800px) {
      .badge{
         display: none;
      }  
      }
