.image{
    width: 100%;
    height: 80vh;
    background-size: 100% 100%;
}

.heading{
    display: block;
}
.headerTitlesLg{
    color: white;
    font-size: 70px;
    font-family: 'Lora', serif;    
}

.headerTitlesSm{
    color: white;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Lora', serif;    
}
.icon{
    color: red;
    transition: 0.5s transform;
}

.icon:hover{
    transform: scale(1.1);
}

@media (max-width:1200px) {
    .image{
        height: 600px;
    }
    .headerTitlesLg{
        font-size: 60px;
    }
    .headerTitlesSm{
        font-size: 33px;
    }
}



