.button{
    color: white;
    background-color: #008080;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.8s all;
    border-spacing: 10px 10px;
    border: 2px solid white;
}

.button:hover {
    outline: 1px solid #008080;
}
