.write {
    padding: 20px;
    background-color: white;
  }
  
  
  .quillEditor {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 300px;
  }
  .writeImg {
    width: 75vw;
    height: 250px;
    border-radius: 10px;
    object-fit: contain;
  }
  
  .writeForm {
    position: relative;
  }
  .writeFormGroup {
    display: flex;
    align-items: center;
  }
  .jodit-editor{
    height: 500px;
    background-color: yellow;
    padding: 20px;
  }
  
  .writeLinkGroup {
    margin-top: 20px;
    align-items: center;
  }
  .link {
    margin-top: 20px;
  }
  .Cats {
    margin-top: 15px;
    padding-left: 20px;
    font-size: larger;
  }
  .category_dropdown {
    border-radius: 10px;
    padding: 2px;
  }
  
  .Category {
    display: flex;
  }
  
  .writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    border-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: 0.8s all;
  }
  
  .writeIcon:hover {
    background-color: black;
    color: white;
  }
  
  .writeInput {
    font-size: 20px;
    border: none;
    padding: 20px;
    width: 70vw;
    color: black;
  }
  
  .writeInput:focus {
    outline: none;
  }
  
  .writeText {
    margin-top: 20px;
    font-size: 20px;
    height: 100vh;
    margin-bottom: 60px;
  }
  
  .writeSubmit {
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .or {
    margin-left: 20px;
  }
  .deleteIcon {
    border: 1px dotted #ccc;
    padding: 10px;
    border-radius: 50%;
    transition: 0.8s all;
  }
  
  .deleteIcon:hover {
    color: white;
    background-color: black;
  }
  @media (max-width: 650px) {
    .writeImg {
      margin-left: 50px;
      width: 75vw;
      height: 250px;
      border-radius: 10px;
      align-items: center;
      object-fit: cover;
    }
  
    .writeForm {
      position: relative;
    }
  
    .writeFormGroup {
      margin-left: 20px;
      display: flex;
      align-items: center;
    }
  
    .writeLinkGroup {
      margin-top: 40px;
      margin-left: 20px;
      align-items: center;
    }
    .quillEditor {
      margin-top: 20px;
      margin-bottom: 100px;
      height: 400px;
      margin-left: 15px;
      margin-right: 15px;
    }
    .write {
      padding-top: 30px;
      margin: 0px;
    }
    .writeSubmit {
      position: relative;
      left: 30px;
      margin-bottom: 20px;
    }
  }
  