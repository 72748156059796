
body{
    background-color: rgb(248, 248, 250);
}
.navCenter{
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.85); 
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content:center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 999;
    color:#fff;
    transition: background-color 0.3s ease, opacity 0.3s ease; /* Add opacity transition */
    opacity: 1; /* Default opacity */
}
.loginNavButton{

    padding: 7px;
    border-radius: 8px;
    border: 1px solid white;
    width: max-content;
}
.loginNavButton:hover{
    color: black;
    background-color: white;
}

.dropdown-item{
   font-weight: 600;
}
.navLeft{
    position: absolute;
    top: 5px;
    left: 20px;
}
.navRight{
    position: absolute;
    right: 20px;
}

.navIcon{
    font-size: 20px;
    margin-right: 5px;
    color: black;
    cursor: pointer;
}

.navImage{
    margin-top: 20px;
    margin-left: 10px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: contain;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.4);
}

.navListItem{
    margin-left: 16px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    list-style: none;
}


.navSearch{
    color: black;
    margin-left: 10px;
    cursor: pointer;
}

.link{
    text-decoration:"none";
    color:"inherit";
  }
.dropdown-menu{
    background-color: black;
    border-radius: 20px;
}
.dropdown-item{
    color: white;
    font-size: 16px;
}
.navMobile{
    display: none;
}
.fa-user{
    border: 2px dotted white;
    border-radius: 50%;
    padding: 5px;
}
@media (max-width: 1000px){
    
    .navMobile{
        background-color: black;
        color: white;
        padding:20px 20px 20px 20px; 
        width: 100%;
        position: sticky;
        top: 0;
        display: block;
        z-index: 999;
        color: white;

    }
    .navCenter{
        display: none;
    }
    .nav{
        display: flex;
        justify-content: space-between;
    }

    .navDropdown{
        margin-top: 20px;
    }
    .navListItem{
        font-size: 16px;
        font-weight: 350;
    }
    .logo{
        display: flex;
        font-weight: bolder;
        font-family: 'Varela', sans-serif;
        align-items: center;
    }

} 