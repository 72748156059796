.QNA-container {
    width: 100%;

}

.QNA-container-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px; 
}

.QNA-container > table{
    width: 100%;
    margin: 20px;
}