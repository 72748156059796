/* Container for each question-answer pair */
.question-answer-container {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Styling for the question */
  .question {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Styling for the answer */
  .answer {
    margin-top: 10px;
  }
  
  /* Style for the "Show Answer" button */
  .show-answer-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .show-answer-button:hover {
    background-color: #0056b3;
  }
  