.privacy{
    padding: 30px;
    background-color: white;
}
.privacy-desc{
    margin-top: 30px;
}
@media (max-width:1000px)
{
    .privacy{
        padding: 20px;
    } 
}
