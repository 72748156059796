.login{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    align-items: center;

    
}
.loginContainer{
    border: 1px solid #ccc;
    padding: 40px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.4);
    min-width: 400px;
}

.loginTitle{
    font-size: 32px;
    font-weight: bold;
}

.loginForm{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
   
 
}

.loginForm > label{
    margin: 10px 0;

}

.loginForm > input{
    padding: 10px;
    background-color: white;
    color: black;
    border: none;
    border-bottom: 1px solid black;

}

.loginButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: rgb(216, 67, 67);
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
}

.loginButton:disabled{
    cursor: not-allowed;
    background-color: lightcoral;
}


@media (max-width: 650px) {
    .loginContainer{
        min-width: max-content;
    }
}