
.home{
    display: flex;
} 
.LatestPostTitle{
    display: flex;
    justify-content: center;
    font-size: 38px;
    font-weight :bold;
    margin-top: 20px;  
    transition: 0.5s transform;
    color: #27374D;
}

.LatestPostTitle:hover{
    transform: translateY(-10px);
}
.loadingIcon{
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 50% ;
}

@media (max-width: 1000px){
    .home{
        margin-top: 40px;
    }
    .col-8{
        width: 100%;
        margin-bottom: 20px;
    }
    .col-4{
        margin-top: 20px;
        width: 100%;
    }
    .LatestPostTitle{
        font-size: 22px; 
    }
} 