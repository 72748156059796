.contact{
    padding: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.contactTitle{
    font-size: 25px;
    font-weight: bold;
}

.contactForm{
    display: flex;
    flex-direction: column;
}

.contactForm > label{
    margin: 10px 0;

}
.contactForm > input{
    width: 100%;
    padding: 10px;
    background-color: white;
    color: black;
    border: none;
    border-bottom: 1px solid black;

}

.contactForm > textarea{
    width: 100%;
    padding: 10px;
    background-color: white;
    color: black;
    border: none;
    border-bottom: 1px solid black;

}

.contactButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: rgba(234, 21, 13, 0.92);
    border: none;
    color: white;
    font-size:large;
    border-radius: 10px;
    padding: 10px;
}
.contactButton:hover{
    background-color: red;
}

