.write-container{
  background-color: white;
}

.write-tab{
  padding: 10px;
  display: inline-flex;
  gap: 10px;
  border-radius: 10px;
  background-color: whitesmoke;
  margin: 20px;
}

.write-tab > button{
  border-radius: 10px;
  padding: 6px;
  color: black;
  background-color: white;
  cursor: pointer;
  transition: 0.8s all;
  border-spacing: 10px 10px;
  border: 2px solid white;
}

.write-tab > button:hover {
  outline: 1px solid #008080;
}

.write-tab > .active{
  background-color: #008080;
  color: white;
}