.container{
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: 1000px){
    .container{
        margin-top: 0px;
    }
    .col-9{
        width: 100%;
        margin-bottom: 20px;
    }
    .col-3{
        margin-top: 20px;
        width: 100%;
    }
} 