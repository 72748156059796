/* Container for each question-answer pair */
.question-answer-container {
  margin: 10px 10px;
  padding: 20px;
  background-color: #f9f9f9;
}

/* Styling for the question */
.question {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Styling for the answer */
.answer {
  margin-top: 10px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tag-container > .tag {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  margin: 3px;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect */
.tag-container > .tag:hover {
  background-color: #0056b3;
}
